<template>
  <v-chip
    small
    dark
    :color="color"
    v-bind="$attrs"
    class="cursor-pointer"
    outlined
    @click="$emit('click')"
    ><slot
      >{{ fuelSource }}
      <v-icon class="ml-2" v-if="fuelSource.length && fuelSources[0] && fuelSources.length <= 2">{{
        fuelSources[0].icon
      }}</v-icon></slot
    ></v-chip
  >
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    shortName: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    profile() {
      return this.$store.getters.user.profile;
    },
    color() {
      let color = this.getFeature('hvac').style.color;
      if (this.profile.settings && this.profile.settings.chip_color) {
        if (this.profile.settings.chip_color.hvac) {
          color = this.profile.settings.chip_color.hvac;
        }
      }
      return color;
    },
    fuelSources() {
      let fuelSources = [];
      if (this.value) {
        let i = this.value;
        for (let s of this.$store.getters.HVACSources) {
          let checked = i[s.value] && i[s.value].value;
          if (checked) {
            fuelSources.push(s);
          }
        }
      }
      return fuelSources;
    },
    fuelSource() {
      let label = 'Specify fuel';
      if (this.fuelSources.length) {
        let fs = this.fuelSources.map(e => {
          if (this.shortName) {
            return e.shortName;
          } else {
            return e.text;
          }
        });
        label = fs.join(', ');
      }
      return label;
    }
  }
};
</script>

<style></style>
